
export class App {
  // version = () : string =>{
  //     return "1.0";  
  // }
}

  
declare var require: {
  <T>(path: string): T;
  (paths: string[], callback: (...modules: any[]) => void): void;
  ensure: (
    paths: string[],
    callback: (require: <T>(path: string) => T) => void
  ) => void;
}; 
 

// import * as $ from 'jquery';
// (<any> window).jQuery = $;
// (<any> window).$ = $;
 
//import 'jquery';
//require("./css/bootstrap.css"); 
//require("./css/font-awesome.min.css");
// require("./css/owl.carousel.css");

//require("./css/main.css"); 

//import  "jquery";;

import   "bootstrap";
  
require("./js/parallax2.js");
require("./js/jquery.sticky.js");  
require("./js/main.js");  

//import styles from './scss/main.scss';


require("./scss/main.scss");


// const navLinks = document.querySelectorAll('.nav-item')
// const menuToggle = document.getElementById('navbarToggleExternalContent');
// const bsCollapse = new bootstrap.Collapse(menuToggle, {toggle:false});
// navLinks.forEach((l) => {
//     l.addEventListener('click', () => { bsCollapse.toggle(); });
// })
//  import * as $ from "jquery";    


// (<any>window).jQuery = $;  
// (<any>window).$ = $;  
  
// require("./js/jquery.ajaxchimp.min.js");

// import "parallax-js";  
//require("../node_modules/parallax-js/dist/parallax.min.js")

// require("./js/owl.carousel.min.js");
// require("./js/jquery.magnific-popup.min.js");
//require("../node_modules/sticky-js/dist/sticky.min.js"); 

//require("./css/linearicons.css");

//import styles from './scss/main.scss';
 
 

//import "./web.config";
 

// $('.navbar-nav>li>a').on('click', function(){
//   $('.navbar-collapse').toggle();
// });
