$( document ).ready(function() {
  // Handler for .ready() called.
  "use strict";

  $(".default-header").sticky({
    topSpacing: 0
  });

  var window_width = $(window).width(),
    window_height = window.innerHeight,
    header_height = $(".default-header").height(),
    // header_height_static = $(".site-header.static").outerHeight(),
    fitscreen = window_height - header_height;


  $(".fullscreen").css("height", window_height);
  $(".fitscreen").css("height", fitscreen);
 



  // if (document.getElementById("default-select")) {
  //   $('select').niceSelect();
  // }        

  // $('.img-pop-up').magnificPopup({
  //     type: 'image',
  //     gallery:{
  //     enabled:true
  //     }
  // });



  // $('.navbar-nav>li>a').on('click', function(){
  //     $('.navbar-collapse').collapse('hide');
  // });
 

  $('.my-parallax-window').parallax(
    {
      src: window_width > 700 ? 'img/website_background.jpg' : 'img/phone_image.jpeg' 
    }
  );

  $('.parallax-slider').attr("alt", "website background");
   
  if(window_width <= 700)
  {
    $('.banner-content').removeClass('align-items-center');
    $('.banner-content').addClass('align-items-start');
  }

  // $('.active-cat-carusel').owlCarousel({
  //     items:3,
  //     loop:true,
  //     autoplay:true,
  //     nav:true,
  //     navText: ["<span class='lnr lnr-arrow-up'></span>","<span class='lnr lnr-arrow-down'></span>"],
  //     responsive:{    
  //     0:{
  //       items: 1
  //     },
  //     480:{
  //       items: 1
  //     },
  //     769:{
  //       items: 3
  //     }
  // }
  // });


  // $('.active-team-carusel').owlCarousel({
  //     items:2,
  //     loop:true,
  //     autoplay:true,
  //     nav:true,
  //     navText: ["<span class='lnr lnr-arrow-up'></span>","<span class='lnr lnr-arrow-down'></span>"],
  //     items : 2, 
  //     itemsDesktop : [992,2],
  //     itemsDesktopSmall : [768,2], 
  //     itemsTablet: [480,1], 
  //     itemsMobile : [320,1]
  // });


  //   $('.active-recent-carusel').owlCarousel({
  //       items:1,
  //       loop:true,
  //       autoplay:true,
  //       dots: true,
  //       0:{
  //         items: 1
  //       },
  //       480:{
  //         items: 1
  //       },
  //       769:{
  //         items: 1
  //       }
  //   });




  // // Select all links with hashes
  // $('.navbar-nav a[href*="#"]')
  //   // Remove links that don't actually link to anything
  //   .not('[href="#"]')
  //   .not('[href="#0"]')
  //   .on('click', function (event) {
  //     // On-page links
  //     if (
  //       location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
  //       location.hostname == this.hostname
  //     ) {
  //       // Figure out element to scroll to
  //       var target = $(this.hash);
  //       target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
  //       // Does a scroll target exist?
  //       if (target.length) {
  //         // Only prevent default if animation is actually gonna happen
  //         event.preventDefault();
  //         $('html, body').animate({
  //           scrollTop: target.offset().top
  //         }, 1000, function () {
  //           // Callback after animation
  //           // Must change focus!
  //           var $target = $(target);
  //           $target.focus();
  //           if ($target.is(":focus")) { // Checking if the target was focused
  //             return false;
  //           } else {
  //             $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
  //             $target.focus(); // Set focus again
  //           }
  //         });
  //       }
  //     }
  //   });

  if(window_width > 700)
  {
    $('.navbar-nav a[href*="#home"]').trigger("click"); 
  }
});
 